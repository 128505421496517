import React from 'react';
import { styled } from '@glitz/react';
import { lightBlack } from 'Shared/Style/colors';
import { pseudo } from '@glitz/core';
import Link from 'Shared/Link';
import Viewport from 'Shared/Viewport';
import AverageRating from 'Product/ProductDetails/AverageRating';
import Image, { Ratio as ImageRatio, Preset as ImagePreset } from 'Shared/Image/Ratio';
import { Spacing, Size } from 'Product/ProductDetails/AverageRating';
import {
  pixelsToUnit,
  minMediumMediaQuery,
  minTinyMediaQuery,
  minMicroMediaQuery,
  minSmallMediaQuery,
  sigma,
  eta,
  milli,
  epsilon,
  kappa,
  theta,
  whitelilac,
} from 'Shared/Style';
import LatestReviewViewModel from '../LatestReviewViewModel.type';
import ClampedText from 'Shared/ClampedText';

const CARD_GUTTER = 25;

type PropType = {
  reviews: LatestReviewViewModel[];
  currentBreakpoint: number;
};

export default (props: PropType) => (
  <React.Fragment>
    {props.reviews.length > 0 && (
      <ListContainer>
        {props.reviews.map((review) => {
          return (
            <ReviewItem key={review.id} css={blockStyle} className="box-item-card">
              <Viewport>
                {(isCompact: boolean) => {
                  return (
                    <BorderedDiv>
                      <Body to={review.productUrl}>
                        <ImageContainer>
                          <Image
                            alt={review.productTitle}
                            itemProp="image"
                            preset={!review.productImageUrl.includes('.gif') ? ImagePreset.Tiny : undefined}
                            ratio={ImageRatio.OneToOne}
                            src={review.productImageUrl}
                            css={{ mixBlendMode: 'multiply' }}
                          />
                        </ImageContainer>
                        <InfoContainer>
                          <ProductTitle lines={2} fontSize={isCompact ? theta : epsilon} bgColor={whitelilac}>
                            {review.productTitle}
                          </ProductTitle>
                          {review.rating > 0 && (
                            <Reviewer>
                              <RatingsWrapper css={ratingStandardStyles}>
                                <StyledAverageRatings
                                  average={review.rating}
                                  spacing={Spacing.Normal}
                                  size={Size.Tiny}
                                />
                              </RatingsWrapper>
                              <AliasName>{review.aliasName}</AliasName>
                            </Reviewer>
                          )}
                          <ClampedText lines={3} fontSize={isCompact ? kappa : sigma} bgColor={whitelilac}>
                            {review.reviewText}
                          </ClampedText>
                        </InfoContainer>
                      </Body>
                    </BorderedDiv>
                  );
                }}
              </Viewport>
            </ReviewItem>
          );
        })}
        {new Array(5).fill(0).map((elem, index) => (
          <Placeholder css={blockStyle} className="box-item-card" key={index} />
        ))}
      </ListContainer>
    )}
  </React.Fragment>
);

const ImageContainer = styled.div({
  width: '25%',
  marginRight: pixelsToUnit(5),
  [minMediumMediaQuery]: {
    width: '33%',
  },
});
const InfoContainer = styled.div({
  alignSelf: 'center',
  width: '75%',
  [minMediumMediaQuery]: {
    width: '66%',
  },
});

const ListContainer = styled.div({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'center',
  [minMediumMediaQuery]: {
    justifyContent: 'space-around',
  },
});

const blockStyle = {
  width: '100%',
  [minSmallMediaQuery]: {
    width: '49%',
  },
  [minMediumMediaQuery]: {
    width: '32.9%',
  },
};

const BorderedDiv = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const Item = styled.div({
  margin: { xy: pixelsToUnit(2) },
  ...pseudo(':nth-child(odd)', {
    paddingRight: pixelsToUnit(7),
  }),
  ...pseudo(':nth-child(even)', {
    paddingLeft: pixelsToUnit(7),
  }),
  [minTinyMediaQuery]: {
    ...pseudo(':nth-child(n)', {
      padding: {
        x: pixelsToUnit(CARD_GUTTER / 2),
      },
    }),
  },
});

const Placeholder = styled(Item, {
  margin: {
    xy: 0,
  },
  padding: {
    xy: 0,
  },
});

const ReviewItem = styled(Item, {
  backgroundColor: (theme) => theme.cardBackgroundColor,
});

const Body = styled(Link, {
  alignItems: 'stretch',
  display: 'flex',
  paddingBottom: pixelsToUnit(5),
  paddingTop: pixelsToUnit(5),
  justifyContent: 'space-between',
  textDecoration: 'inherit',
  color: 'inherit',
  textAlign: 'left',
});

const ratingStandardStyles = {
  [minMicroMediaQuery]: {
    fontSize: eta,
  },
  [minSmallMediaQuery]: {
    fontSize: sigma,
  },
  [minMediumMediaQuery]: {
    fontSize: milli,
    bottom: 0,
    position: 'static',
    textAlign: 'left',
  },
};

const RatingsWrapper = styled.div({
  bottom: pixelsToUnit(-6),
  fontSize: pixelsToUnit(9),
  width: pixelsToUnit(95),
  marginRight: pixelsToUnit(5),
  [minMediumMediaQuery]: {
    bottom: 0,
    position: 'static',
    textAlign: 'right',
  },
});

const StyledAverageRatings = styled(AverageRating, {
  whiteSpace: 'nowrap',
});

const ProductTitle = styled(ClampedText, {
  marginTop: 0,
  color: lightBlack,
  fontWeight: 800,
});

const Reviewer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const AliasName = styled.span({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: 'bold',
  overflow: 'hidden',
  minWidth: pixelsToUnit(40),
});
