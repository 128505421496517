import React from 'react';
import { styled } from '@glitz/react';
import { lightBlack } from 'Shared/Style/colors';
import Link from 'Shared/Link';
import AverageRating from 'Product/ProductDetails/AverageRating';
import Image, { Ratio as ImageRatio, Preset as ImagePreset } from 'Shared/Image/Ratio';
import { Spacing, Size } from 'Product/ProductDetails/AverageRating';
import {
  pixelsToUnit,
  minMediumMediaQuery,
  minSmallMediaQuery,
  epsilon,
  theta,
  kappa,
  sigma,
  whitelilac,
} from 'Shared/Style';
import LatestReviewViewModel from '../LatestReviewViewModel.type';
import { medium, large, huge } from 'Shared/Style/spacing';
import Viewport from 'Shared/Viewport';
import ClampedText from 'Shared/ClampedText';

type PropType = {
  reviews: LatestReviewViewModel[];
};

export default (props: PropType) => {
  return (
    props.reviews.length > 0 && (
      <ListContainer>
        {props.reviews.map(review => {
          return (
            <Item key={review.id}>
              <Viewport>
                {(isCompact: boolean) => {
                  return (
                    <BorderedDiv>
                      <Body to={review.productUrl}>
                        <ImageWrapper>
                          <Image
                            alt={review.productTitle}
                            itemProp="image"
                            preset={!review.productImageUrl.includes('.gif') ? ImagePreset.Tiny : undefined}
                            ratio={ImageRatio.OneToOne}
                            src={review.productImageUrl}
                            css={{ mixBlendMode: 'multiply' }}
                          />
                        </ImageWrapper>
                        <LeftContent>
                          <ProductTitle lines={2} fontSize={isCompact ? theta : epsilon} bgColor={whitelilac}>
                            {review.productTitle}
                          </ProductTitle>
                          {review.rating > 0 && (
                            <Reviewer>
                              <RatingsWrapper>
                                <StyledAverageRatings
                                  average={review.rating}
                                  spacing={Spacing.Normal}
                                  size={Size.Tiny}
                                />
                              </RatingsWrapper>
                              <AliasName>{review.aliasName}</AliasName>
                            </Reviewer>
                          )}
                          <ClampedText lines={3} fontSize={isCompact ? kappa : sigma} bgColor={whitelilac}>
                            {review.reviewText}
                          </ClampedText>
                        </LeftContent>
                      </Body>
                    </BorderedDiv>
                  );
                }}
              </Viewport>
            </Item>
          );
        })}
      </ListContainer>
    )
  );
};

const ListContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: {
    x: large,
  },
  [minMediumMediaQuery]: {
    justifyContent: 'space-around',
    x: huge,
  },
});

const BorderedDiv = styled.div({
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
});

const Item = styled.div({
  marginBottom: pixelsToUnit(10),
});

const Body = styled(Link, {
  backgroundColor: theme => theme.cardBackgroundColor,
  padding: { xy: pixelsToUnit(5) },
  alignItems: 'stretch',
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  textDecoration: 'inherit',
  color: 'inherit',
  textAlign: 'center',
  [minMediumMediaQuery]: {
    textAlign: 'left',
  },
});

const ImageWrapper = styled.div({
  width: '20%',
  overflow: 'hidden',
});

const LeftContent = styled.div({
  alignItems: 'flex-start',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: large,
  paddingRight: large,
  [minMediumMediaQuery]: {
    paddingLeft: huge,
  },
  paddingBottom: 0,
  textDecoration: 'inherit',
  color: 'inherit',
  textAlign: 'left',
  width: '80%',
});

const RatingsWrapper = styled.div({
  fontSize: pixelsToUnit(9),
  width: pixelsToUnit(100),
  bottom: 0,
  position: 'static',
  textAlign: 'right',
});

const StyledAverageRatings = styled(AverageRating, {
  whiteSpace: 'nowrap',
  textAlign: 'left',
});

const ProductTitle = styled(ClampedText, {
  color: lightBlack,
  fontWeight: 800,
  wordBreak: 'break-word',
  [minMediumMediaQuery]: {
    marginTop: medium,
  },
});

const Reviewer = styled.div({
  display: 'flex',
  alignItems: 'center',
  [minSmallMediaQuery]: {
    flexDirection: 'row',
  },
});

const AliasName = styled.span({
  fontWeight: 'bold',
});
