/**
 * @ComponentFor LatestReviewsBlockViewModel
 */
import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import connect from 'Shared/connect';
import ILatestReviewsBlockViewModel from './LatestReviewsBlockViewModel.type';
import HorizontalLatestReview from './components/HorizontalLatestReview';
import VerticalLatestReview from './components/VerticalLatestReview';
import { BOX_ITEM_CLASSNAME } from 'Shared/BoxLayout';
import { lightBlack } from 'Shared/Style/colors';
import { beta, delta } from 'Shared/Style/typography';
import { pixelsToUnit, minMediumMediaQuery, small, huge } from 'Shared/Style';

type ConnectStateType = {
  currentBreakpoint: number;
};

const LatestReviews = (props: ILatestReviewsBlockViewModel & ConnectStateType & StyledProps) => {
  return (
    <Container className={BOX_ITEM_CLASSNAME}>
      <HeaderTitle>{props.title}</HeaderTitle>
      {props.isVertical ? (
        <VerticalLatestReview reviews={props.reviews} />
      ) : (
        <HorizontalLatestReview reviews={props.reviews} currentBreakpoint={props.currentBreakpoint} />
      )}
    </Container>
  );
};

export default styled(
  connect(
    (state): ConnectStateType => ({
      currentBreakpoint: state.currentBreakpoint,
    }),
  )(LatestReviews),
);

const Container = styled.div({});

const HeaderTitle = styled.h2({
  color: lightBlack,
  fontSize: delta,
  fontWeight: 'bold',
  letterSpacing: pixelsToUnit(1.9),
  lineHeight: 'normal',
  padding: { xy: small },
  textAlign: 'center',
  textTransform: 'uppercase',
  [minMediumMediaQuery]: {
    fontSize: beta,
    fontWeight: 'normal',
    letterSpacing: pixelsToUnit(3),
    padding: { xy: huge },
  },
});
